import React, { useState } from 'react'
import { Link } from 'react-router-dom'
// import LogoLight from "../assets/images/logo-light.png";
// import LogoDark from "../assets/images/logo-dark.png";
// import LogoLight from "../assets/images/logo-no-background.png";
import LogoLight from "../assets/images/logo-ridetracker.png";
import LogoDark from "../assets/images/logo-ridetracker.png";
import AuthNavbarButtons from './auth/AuthNavbarButtons';
import { useSelector } from 'react-redux';

function Navbar(props) {

    // const activefield = props.location.pathname.split('/')[1].toLowerCase()
    const [isOpen, setMenu] = useState(true);
    const toggleMenu = () => {
        setMenu(!isOpen)
    }

    const isLoggedin = useSelector(state => state.auth.isAuthenticated)

    return (
        <nav className="navbar is-sticky z-20" id="navbar">
            <div className="container flex flex-wrap items-center justify-end">
                <a className="navbar-brand" href="/">
                    <span className="inline-block dark:hidden">
                        <img src={LogoDark} className="l-dark w-36" alt="" />
                        <img src={LogoLight} className="l-light w-36" alt="" />
                    </span>
                    <img
                        src={LogoLight}
                        className="hidden dark:inline-block w-36"
                        alt=""
                    />
                </a>


                <AuthNavbarButtons />

                {
                    isLoggedin ?
                        <div className="flex md:flex md:flex-grow flex-row mt-6 mb-6">
                            <ul className="navbar-nav nav-light" id="navbar-navlist">

                                <Link className="nav-item py-4 px-2" to="/rides/create/">
                                    <span className="nav-link">Add</span>
                                </Link>
                                <Link className="nav-item py-4 px-2 " to="/rides/">
                                    <span className="nav-link">Rides</span>
                                </Link>
                                <Link className="nav-item py-4 px-2 " to="/cars/">
                                    <span className="nav-link">Cars</span>
                                </Link>
                                {/* <Link className="nav-item py-4 px-2 " to="/ridepoints/" >
                                    <span className="nav-link">Ridepoints</span>
                                </Link> */}

                                <Link className="nav-item py-4 px-2" to="/addressbook">
                                    <span className="nav-link">Addressbook</span>
                                </Link>

                            </ul>
                        </div>
                        :
                        <div className="flex md:flex md:flex-grow flex-row my-6">
                            <ul className="navbar-nav nav-light" id="navbar-navlist-right">


                                <Link className="nav-item py-4 px-2 " to="/">
                                    <span className="nav-link">Home</span>
                                </Link>
                                <Link className="nav-item py-4 px-2 " to="/features">
                                    <span className="nav-link">Features</span>
                                </Link>

            
                                <Link className="nav-item py-4 px-2 " to="/pricing">
                                    <span className="nav-link">Pricing</span>
                                </Link>
                                <Link className="nav-item py-4 px-2 " to="/contact">
                                    <span className="nav-link">Contact</span>
                                </Link>


                            </ul>
                        </div>
                }


            </div>
        </nav>
    )
}

export default Navbar