import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../../redux/actions/auth'

function AuthNavbarButtons() {
    const dispatch = useDispatch()
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

    if (!isAuthenticated) {
        return (
            <div className="nav-icons flex items-center lg_992:order-2 ms-auto">
                <ul className="list-none menu-social mb-0">
                    <li className="inline ms-1">
                        <a href="/login" className="">
                            <button className="btn btn-sm rounded-full hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-orange hover:text-white">

                                Login
                            </button>
                        </a>
                        <a href="/signup">
                            <button className="btn btn-sm rounded-full hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-orange hover:text-white">
                                Signup
                            </button>
                        </a>
                    </li>
                </ul>
            </div>
        )
    } else {
        return (
            <div className="nav-icons flex items-center lg_992:order-2 ms-auto">
                <ul className="list-none menu-social mb-0">
                    <li className="inline ms-1">
                        <a href="/profile">
                            <button className="btn btn-sm rounded-full bg-orange-700 hover:border-orange-600 border-orange-700 hover:text-orange text-white">
                                Profile
                            </button>
                        </a>
                        <button
                            className="btn btn-sm rounded-full hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-orange hover:text-white"
                            onClick={() => dispatch(logout())}
                        >
                            Logout
                        </button>
                    </li>
                </ul>
            </div>
        )
    }

}

export default AuthNavbarButtons