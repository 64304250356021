import React, { useEffect, useState } from 'react'
import BasePage from '../../component/layout/BasePage'
import Toggle from '../../component/formfields/Toggle'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import AddressDetailSection from './components/AddressDetailSection'
import AddressEditForm from './components/AddressEditForm'
import axiosInstance from '../../utils/axiosInstance'

function AddressDetail() {
    // detail page for a single address
    // renders either a AddressDetailSection or AddressEditForm component, depending on editMode state

    const { addressId } = useParams()
    
    const [addressData, setAddressData] = useState([]);
    const [editMode, setEditMode] = useState(false);


    // get address data from api, set it to state
    useEffect(() => {
        const getAddress = async () => {
            try {
                axiosInstance.get(`/api/addresses/${addressId}`)
                    .then(res => {
                        setAddressData(res.data)
                    })

            }
            catch (error) {
                console.log(error);
            }
        }
        getAddress();

    }, []);


    return (
        <BasePage>

            <div className='container'>
                <div className="px-4 sm:px-0">
                    <h3 className="text-base font-semibold leading-7 text-gray-900">Address details</h3>

                </div>

                {/* right aligned div */}
                <div className="flex justify-end">
                    <Toggle
                        checked={editMode}
                        onChangeHandler={() => setEditMode(currentstate => !currentstate)}
                        label="Edit mode"
                    />
                </div>
                {editMode ? (
                    <div className="mb-5">
                        <AddressEditForm addressData={addressData} setAddressData={setAddressData} setEditMode={setEditMode} />
                    </div>
                ) : (
                    <AddressDetailSection addressData={addressData} />
                )}


            </div>
        </BasePage>
    )

}


export default AddressDetail